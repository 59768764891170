<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <el-row style="padding: 10px 20px">
        <el-descriptions title="货源信息" :column="2" border>
          <el-descriptions-item label="货源编号">{{
            addForm.pno
          }}</el-descriptions-item>
          <el-descriptions-item label="货物类别">{{
            addForm.cargoType
          }}</el-descriptions-item>
          <el-descriptions-item label="货物名称">{{
            addForm.cargoType
          }}</el-descriptions-item>
          <el-descriptions-item label="货物吨数">
            {{ addForm.plantons + "(吨)" }}
          </el-descriptions-item>
        </el-descriptions>
      </el-row>
      <!--<el-row>
        <el-button
          type="primary"
          class="buttons"
          size="small"
          @click="handleResetDispatch"
          style="margin: 10px 20px"
          >重新分派</el-button
        >
      </el-row>-->
      <el-row style="padding: 10px 20px">
        <div>当前车队：{{ fleetName }}</div>
        <el-button
          type="primary"
          size="small"
          style="float: right"
          @click="handleAddDriver"
          >新增司机</el-button
        >
        <el-table :data="driverTable" max-height="400px">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
          <el-table-column prop="plate" label="车牌号"> </el-table-column>
          <el-table-column prop="driverName" label="司机姓名">
          </el-table-column>
          <el-table-column prop="telephone" label="手机号"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="handleRemove(scope.row.dispatchId)"
                style="color: rgb(254, 90, 36)"
              >
                取消分派
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
    <DriverDialog
      v-if="showDriver"
      ref="diverDialogRef"
      :fleetId="fleetId"
      :planId="planId"
      @confirm="handleDriverConfirm"
      @cancel="handleDriverCancel"
    ></DriverDialog>
  </div>
</template>
<script>
import DriverDialog from "../module/fleetDriverDialog.vue";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        pno: "",
        cargoType: "",
        cargoTypeName: "",
        plantons: "",
        dispatchStatus: "",
      },
      planId: this.$route.query.id,
      fleetId: "",
      fleetName: "",
      fleetTable: [],
      driverTable: [],
      showDriver: false,
    };
  },
  components: {
    DriverDialog,
  },
  computed: {},
  created() {
    this.fleetId = this.$route.query.fleetId;
    this.fleetName = this.$route.query.fleetName;
    this.getDetails();
    this.getDriverTable();
  },
  methods: {
    getDetails() {
      this.$http(
        "api1",
        "/api/biz/PlanInfo/info/" + this.$route.query.id,
        "get"
      ).then((res) => {
        for (let key in this.addForm) {
          this.addForm[key] = res.data.data[key];
        }
        this.activeName = "second";
        this.getDriverTable();
      });
    },
    handleClick() {
      if (this.activeName == "first") {
        this.getFleetTable();
      } else {
        this.getDriverTable();
      }
    },
    getDriverTable() {
      this.$http("api1", "/api/biz/PlanFleet/fleetDispatchedCarList", "get", {
        planId: this.$route.query.id,
        fleetId: this.fleetId,
      }).then((res) => {
        if (res.data.code != 0) {
          this.$message.error(res.data.msg);
          return;
        }
        this.driverTable = res.data.data;
      });
    },
    handleAddDriver() {
      this.showDriver = true;
      this.$nextTick(() => {
        this.$refs.diverDialogRef.planId = this.$route.query.id;
      });
    },
    handleDriverConfirm() {
      this.showDriver = false;
      this.getDriverTable();
    },
    handleDriverCancel() {
      this.showDriver = false;
    },
    handleRemove(id) {
      this.$messageBox
        .confirm("确认取消分派, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          this.$http("api1", "/api/biz/PlanFleet/cancelDispatchCar", "post", {
            dispatchType: 1,
            dispatchId: id,
          }).then((res) => {
            if (res.data.code != 0) {
              this.$message.error(res.data.msg);
              return;
            }
            this.$message.success("操作成功！");

            this.getDriverTable();
          });
        });
    },
    handleResetDispatch() {
      this.$messageBox
        .confirm("重新分派将清空当前分派, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          this.$http("api1", "/api/biz/PlanInfo/dispatchReset", "post", {
            planId: this.$route.query.id,
          }).then((res) => {
            if (res.data.code != 0) {
              this.$message.error(res.data.msg);
              return;
            }
            this.$message.success("操作成功！");
            if (this.activeName == "first") {
              this.getFleetTable();
            } else {
              this.getDriverTable();
            }
            this.getDetails();
          });
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped></style>
